@import './reset';
@import './atom';
@import './flex';
@import './antdReset';
@import (reference) "./mixins";


:root {
  --theme: light;
  --white: white;
  --black: rgb(48, 48, 48);
  --black2: rgb(120, 120, 120);
  --gray: rgb(250, 250, 250);
  --primary: rgb(29, 147, 171);
  --second: #f2f9fb;
  --hover-color: #f3f3f3;
  --bar-color: rgba(0, 0, 0, 0.1);
  --theme-color: var(--gray);
  --shadow: 20px 20px 100px 8px rgba(0, 0, 0, 0.08);
  --card-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  --border-in-light: 1px solid rgb(222, 222, 222);
  --window-width: 92vw;
  --window-height: 92vh;
  --sidebar-width: 240px;
  --main-width: calc(100% - var(--sidebar-width));
  --message-min-width: 40%;
  --message-max-width: 80%;
  --full-height: 100%;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: pan-x pan-y;
}

* {
  -webkit-tap-highlight-color: transparent;
  // user-select: none;
  -webkit-touch-callout: none;
}

#root {
  position: relative;
}